// import { useTheme } from '@emotion/react';
import { useTheme } from '@emotion/react';
import { Close, Delete, ThumbUpSharp } from '@mui/icons-material';
import {
  // Avatar,
  // AvatarGroup,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  // Card,
  // CardContent,
  // CardMedia,
  // CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // Divider,
  Grid,
  IconButton,
  // ImageListItemBar,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  // Table,
  // TableBody,
  // TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  // TableHead,
  // TableRow,
  // TextField,
  // ToggleButton,
  // ToggleButtonGroup,
  // Tooltip,
  Typography
} from '@mui/material';
import { getAnomalyBays, getBayAnomalyList, SendAlert, UpdateMetadataStatus } from 'api';
// import { EasyZoomOnHover } from 'easy-magnify';
import { useEffect, useState } from 'react';
// import { CgSpinner } from 'react-icons/cg';
// import { MdOutlineDone } from 'react-icons/md';
// import { RiErrorWarningLine } from 'react-icons/ri';

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '8px 16px',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    transform: 'translateY(-2px)'
  }
}));

const StyledCard = styled(Card)(() => ({
  borderRadius: '12px',
  overflow: 'hidden',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)'
  }
}));

const StyledChip = styled(Chip)(() => ({
  margin: '2px',
  fontWeight: 'bold'
}));
const Anomalies = () => {
  const theme = useTheme();
  const storedAnalysisStoreId = localStorage.getItem('analysisStoreId');
  const analysisStoreId = storedAnalysisStoreId ? JSON.parse(storedAnalysisStoreId) : null;
  // const success = theme.palette.success.main;
  // const successDark = theme.palette.success.dark;
  // const warning = theme.palette.warning.main;
  // const warningDark = theme.palette.warning.dark;
  // const error = theme.palette.error.main;
  const [activeButton, setActiveButton] = useState(0);
  // const [loading, setLoading] = useState(false);
  const [bayList, setBayList] = useState([]);
  const [cardData, setCardData] = useState([]);
  // const [ignoreLoading, setIgnoreLoading] = useState(null);
  // const [selectedImage, setSelectedImage] = useState(null);
  // const [liveAnomalyImg, setLiveAnomalyImg] = useState(true);
  // const [timestamps, setTimestamps] = useState({ date: '', time: '' });
  // const [loadsend, setLoadsend] = useState(false);
  // const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  // const [anomalyDetails, setAnonmalyDetails] = useState([]);
  // const [solvedLoading, setSolvedLoading] = useState(null);
  // const [anomalyType, setAnomalyType] = useState('');
  // const [imageLoading, setImageLoading] = useState(false);
  // const [antn, setAntn] = useState(false);
  // const [pos, setPos] = useState(false);
  // const [natural, setNaturel] = useState({ wdth: false, hght: false });
  const [alertData, setAlertData] = useState({
    zone_id: false,
    shelf_id: false,
    // group_id: false,
    user_name: false,
    user_id: false,
    user_number: false,
    // user_email: false,
    // user_role: false,
    anomaly_type: false,
    message: false
  });
  const handleButtonClick = async (index, baysDetails) => {
    setActiveButton(index);
    // setLoading(true);
    const data = await getBayAnomalyList({ bay_id: baysDetails._id });
    setCardData(data.data);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAnomalyBays({
          store: analysisStoreId
        });
        console.log('This is the bay list now', data);
        setBayList(data.data);
      } catch (error) {
        console.log('Error fetching the anomaly bay list', error);
      }
    };

    fetchData();
  }, [analysisStoreId]);

  useEffect(() => {
    const fetchDataForCards = async () => {
      if (bayList.length > 0) {
        try {
          const data = await getBayAnomalyList({ bay_id: bayList[0]._id });
          setCardData(data.data);
        } catch (error) {
          console.log('Error fetching the anomaly bay list', error);
        }
      }
    };

    fetchDataForCards();
  }, [bayList]);
  useEffect(() => {
    async function sendAlertMsg() {
      // console.log(alertData);
      if (alertData.user_id) {
        console.log('Number:', alertData.user_number);
        const mssg = alertData.message === '' ? ' ' : alertData.message;
        // const num = alertData.user_number ==='3024011800' ? '9960240543':data.user_number;
        const API_KEY =
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1NWY2MmE5Yzk4Nzk3MGFlZWM1ZTg0MCIsIm5hbWUiOiJOZW9QaHl0ZSIsImFwcE5hbWUiOiJBaVNlbnN5IiwiY2xpZW50SWQiOiI2NTVmNjJhOGM5ODc5NzBhZWVjNWU4M2IiLCJhY3RpdmVQbGFuIjoiQkFTSUNfTU9OVEhMWSIsImlhdCI6MTcwMDc0OTk5M30.8-SugzKOaRlF3BFhgTn944znZnsydeoUPudFEIZdNWs'; // Replace with your actual API key
        const API_URL = 'https://backend.aisensy.com/campaign/t1/api/v2';
        const formatDataForAPI = (data) => {
          return {
            apiKey: API_KEY,
            campaignName: 'disha_fashion_alert_campaign',
            destination: data.user_number,
            userName: data.user_name,
            templateParams: ['$AgentName', '$BayId', '$ShelfId', '$AnomaliesTypes', '$BayId', '$ShelfId', '$CustomMessage'],
            tags: ['AgentName', 'BayId', 'ShelfId', 'AnomaliesTypes', 'BayId', 'ShelfId', 'CustomMessage'],
            attributes: {
              AgentName: data.user_name,
              BayId: data.zone_id,
              ShelfId: data.shelf_id,
              AnomaliesTypes: data.anomaly_type,
              //eslint-disable-next-line
              BayId: data.zone_id,
              //eslint-disable-next-line
              ShelfId: data.shelf_id,
              CustomMessage: mssg
            }
          };
        };

        const formattedData = formatDataForAPI(alertData);
        const status = await SendAlert(formattedData, API_KEY, API_URL);
        console.log('status', status);

        if (status) {
          setLoadsend(false);
          setSnackbarOpen(true);
          setAlertData({
            zone_id: false,
            shelf_id: false,
            // group_id: false,
            user_name: false,
            user_id: false,
            user_number: false,
            // user_email: false,
            // user_role: false,
            anomaly_type: false,
            message: false
          });
          //  console.log('hello');
        }
      }
    }
    sendAlertMsg();
  }, [alertData]);
  // const getRowStyle = (isHovered) => ({
  //   backgroundColor: isHovered ? '#f5f5f5' : 'inherit',
  //   transition: 'background-color 0.3s',
  //   overflow: 'unset'
  // });
  const [selectedImage, setSelectedImage] = useState(null);
  const [newComments, setNewComments] = useState(bayList.map((item) => ({ id: item.id, comment: '' })));
  const handleCommentChange = (id, value) => {
    setNewComments((prev) => prev.map((item) => (item.id === id ? { ...item, comment: value } : item)));
  };

  const updateMetadataIgnore = async (id) => {
    // console.log('id:', id);
    // setIgnoreLoading(true);
    try {
      const response = await UpdateMetadataStatus(id);
      if (response) {
        console.log('AnomalyDetails api', response);
        // setAnonmalyDetails(response.data);
        // setIgnoreLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSolved = (id) => {
    console.log('required ID', id);
    updateMetadataIgnore(id);
  };

  return (
    <Grid container spacing={3} sx={{ padding: '30px' }}>
      <Grid item xs={12}>
        <Paper
          elevation={6}
          sx={{
            padding: '40px',
            borderRadius: '20px',
            background: `linear-gradient(145deg, ${theme.palette.background.paper}, ${theme.palette.background.default})`,
            boxShadow: theme.shadows[10]
          }}
        >
          <Box display="flex" justifyContent="center" flexWrap="wrap" gap={2} mb={5}>
            {bayList.map((item, i) => (
              <StyledButton
                key={i}
                onClick={() => handleButtonClick(i, item)}
                variant={activeButton === i ? 'contained' : 'outlined'}
                color={activeButton === i ? 'primary' : 'secondary'}
              >
                {item.bay_name.toUpperCase()}
              </StyledButton>
            ))}
          </Box>

          <TableContainer component={Paper} sx={{ borderRadius: '15px', overflow: 'hidden', boxShadow: theme.shadows[3] }}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
                  <TableCell sx={{ color: theme.palette.primary.contrastText, fontWeight: 'bold' }}>Image</TableCell>
                  <TableCell sx={{ color: theme.palette.primary.contrastText, fontWeight: 'bold' }}>Details</TableCell>
                  <TableCell sx={{ color: theme.palette.primary.contrastText, fontWeight: 'bold' }}>Comments</TableCell>
                  <TableCell sx={{ color: theme.palette.primary.contrastText, fontWeight: 'bold' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cardData.map((item, ind) => (
                  <TableRow key={ind} hover sx={{ '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover } }}>
                    <TableCell>
                      <StyledCard>
                        <CardMedia
                          component="img"
                          image={item.image_url}
                          alt={item.shelf_name}
                          sx={{ height: 150, cursor: 'pointer' }}
                          onClick={() => setSelectedImage(item)}
                        />
                      </StyledCard>
                    </TableCell>
                    <TableCell>
                      <CardContent>
                        <Typography variant="h6" color="primary" gutterBottom>
                          {item.shelf_name}
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                          {item.part_name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          Date: {new Date(item.anomalies.timestamps).toLocaleDateString()}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          Days until resolved: {item.days_until_resolved}
                        </Typography>
                        <Box display="flex" flexWrap="wrap" gap={1}>
                          {item.anomalies.anomalies_found.map((tag, index) => (
                            <StyledChip key={index} label={tag.type.split('_')[0].toUpperCase()} color="error" />
                          ))}
                        </Box>
                      </CardContent>
                    </TableCell>
                    <TableCell>
                      <TextField
                        placeholder="Add a comment..."
                        variant="outlined"
                        value={newComments.find((c) => c.id === item.id)?.comment || ''}
                        onChange={(e) => handleCommentChange(item.id, e.target.value)}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                      />
                      <Button
                        onClick={() => handleCommentChange(item.id)}
                        disabled={!newComments.find((c) => c.id === item.id)?.comment}
                        variant="contained"
                        fullWidth
                      >
                        Alert Store
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Button
                          onClick={() => handleSolved(item.anomalies.analysis_id)}
                          variant="outlined"
                          fullWidth
                          startIcon={<ThumbUpSharp />}
                        >
                          Solved
                        </Button>
                        <Button
                          onClick={() => handleSolved(item.anomalies.analysis_id)}
                          variant="outlined"
                          color="error"
                          fullWidth
                          startIcon={<Delete />}
                        >
                          Ignore
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Dialog open={!!selectedImage} onClose={() => setSelectedImage(null)} maxWidth="md" fullWidth>
            {selectedImage && (
              <>
                <DialogTitle
                  sx={{
                    m: 0,
                    p: 2,
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText
                  }}
                >
                  {selectedImage.shelf_name} || {selectedImage.part_name}
                  <IconButton
                    aria-label="close"
                    onClick={() => setSelectedImage(null)}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: theme.palette.primary.contrastText
                    }}
                  >
                    <Close />
                  </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                  <Box display="flex" gap={3} alignItems="flex-start">
                    <Box flex="2" display="flex" justifyContent="center" alignItems="center">
                      <img
                        src={selectedImage.image_url}
                        alt={selectedImage.shelf_name}
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          objectFit: 'contain',
                          borderRadius: '8px'
                        }}
                      />
                    </Box>
                    <Box flex="1" display="grid" gap={3}>
                      <Box>
                        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                          Time
                        </Typography>
                        <Typography>{new Date(selectedImage.anomalies.timestamps).toLocaleDateString()}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                          Anomalies
                        </Typography>
                        <Box display="flex" flexWrap="wrap" gap={1}>
                          {selectedImage.anomalies.anomalies_found.map((tag, index) => (
                            <StyledChip key={index} label={tag.type.split('_')[0].toUpperCase()} color="error" />
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', padding: 2 }}>
                  <Button onClick={() => setSelectedImage(null)} color="primary">
                    Close
                  </Button>
                  <Button onClick={() => handleSolved(selectedImage.anomalies.analysis_id)} color="error" startIcon={<Delete />}>
                    Ignore
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Anomalies;
